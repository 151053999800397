/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import 'theme/theme-variables';

// 3rd party libraries
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// Theme customization
@import 'theme/theme';

/*
 * Typography
 * ---------------------------------
 */

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

* {
  font-family: 'Lato', sans-serif;
}

body {
  color: $primary-font-color;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

h3 {
  font-size: 18px;
  line-height: 22px;
}

.problem-solver-list h3,
app-suggestion-status-records h3 {
  margin-bottom: 20px;
}

.agm-map-container-inner,
.carousel-inner {
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px #888888;
}

/*
 * General Layout
 */

.lb-logo {
  padding: 20px 22px 10px 22px;
}
.lb-closeContainer {
  position: absolute !important;
  top: -50px !important;
}
$side-pane-width: 350px;
$hide-sidepane-width: 1200px;
$min-body-height: 500px;

.left-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 350px;
  width: 350px;
  background-color: rgb(53, 142, 198);

  @media (max-width: $hide-sidepane-width) {
    position: relative;
    float: left;
  }

  -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.26);
  z-index: 999;

  .burgerMenu {
    float: right;
    .btn-outline-primary {
      &:hover,
      &:active,
      &.dropdown-toggle,
      &:not(:disabled):not(.disabled):active {
        background: none;
      }
    }
  }
}

.right-container {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 350px;
  min-width: 560px;
}

/*
 * Detail Layout
 * ---------------------------------
 */

.details-header {
  padding: 40px 40px 40px 55px;
}

.dd-main-pane {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  @media (min-width: $hide-sidepane-width) {
    width: calc(100% - 350px);
  }
  @media (max-width: $hide-sidepane-width) {
    width: 100%;
  }

  .btn-group .btn-light.btn {
    &:first-child {
      margin-left: 40px;
    }

    &.active {
      background: #c6e1e4;
    }
  }
}

.dd-side-pane {
  padding: 40px 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  z-index: 999;
  @media (min-width: $hide-sidepane-width) {
    display: block;
  }

  @media (max-width: $hide-sidepane-width) {
    display: none;
  }
}

.not-found-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.details-displayName {
  font-size: 26px;
  color: #333;
}

.details-creation-date {
  height: 20px;
  font-size: 15px;
  line-height: 20px;
}

.btn-sidepane-handle {
  border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -webkit-border-radius: 15px 0 0 15px;

  position: absolute;
  top: 10px;
  right: 0;
  @media (min-width: $hide-sidepane-width) {
    display: none;
  }
}

.messages-container {
  height: calc(100vh - 245px);
  min-height: calc(#{$min-body-height} - 245px);
  overflow-y: auto;
  overflow-x: hidden;
}

app-public-comments .messages-container {
  height: calc(100vh - 280px);
  min-height: calc(#{$min-body-height} - 280px);
}

.messages-container-disabled {
  background-color: #e8e8e8;
  background: linear-gradient(
    338.21deg,
    #b2b2b2 0%,
    rgba(212, 212, 212, 0.66) 65.85%,
    rgba(255, 255, 255, 0) 100%
  );
}

/*
 * Button Groups (List Tab Menu)
 * ---------------------------------
 */

.btn-group .btn-light.btn {
  background: #e8e8e8;
  border: 0px solid #000000;
  font-weight: bold;
  margin-left: 6px;
  padding: 10px 50px 10px 15px;

  &:first-child {
    margin-left: 12px;
  }

  &.active {
    background: white;
    border-color: white;
  }
  &.btn-chat {
    font-size: 26px;
    padding: 0px 10px;
  }
}

.btn-group .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-radius: 15px 15px 0px 0px;
  -moz-border-radius: 15px 15px 0px 0px;
  -webkit-border-radius: 15px 15px 0px 0px;
}

// style tabs
.left-tab a.nav-link {
  background-color: lightgray;
  color: black;
}

.left-tab a.nav-link.active {
  background-color: white;
  font-weight: bold;
}

.tab-fixed-height {
  height: calc(100vh - 138px);
  min-height: calc(#{$min-body-height} - 138px);
  width: 100%;
}

.tab-button {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 15px;
}

/*
 * List
 * ---------------------------------
 */

$dd-list-items-number-height: 45px;
.dd-list-items-number {
  height: $dd-list-items-number-height;
  line-height: $dd-list-items-number-height;
  padding-left: 12px;
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.dd-list {
  overflow-y: auto;
  height: calc(100% - #{$dd-list-items-number-height});
  background-color: white;

  .dd-list-row {
    border-top: 1px solid #eeeeee;
    cursor: pointer;

    &:hover {
      background-color: rgba(175, 219, 224, 0.6);
    }
  }

  .selected {
    background-color: rgba(175, 219, 224, 0.6);
  }

  .not-selected {
    color: #9b9b9b;
  }
}

/*
 * List items
 * ---------------------------------
 */

.dd-list-item {
  font-size: 0.75em;
  position: relative;
  overflow: hidden;
  padding: 12px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dd-worker {
    background-color: rgba(152, 222, 232, 0.08);
  }

  &.dd-not-worker {
    background-color: rgba(221, 60, 93, 0.08);
  }

  .dd-title {
    font-weight: bold;
    font-size: 15px;
    color: #333;
  }
  .dd-detail-text {
    font-size: 14px;
    float: left;
    width: calc(75%);
    font-style: italic;
    margin-top: 3px;
    font-size: 13px;
    letter-spacing: -0.3px;
    font-weight: 500;
    line-height: 14px;
    height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dd-content {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.36px;
  }

  .dd-tag-worker {
    width: 4px;
    height: 100%;
    background-color: #98dee8;
    position: absolute;
    top: 0;
    left: 0;
  }

  .dd-icon {
    float: left;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #1f82c0;
    overflow: hidden;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .dd-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .dd-text {
    float: right;
    padding-left: 1em;
    width: calc(100% - 5em);
    letter-spacing: -0.36px;
  }

  .dd-updates {
    position: absolute;
    top: 38px;
    right: 16px;
  }

  .dd-time {
    position: absolute;
    top: 13px;
    right: 12px;
  }
}

/*--------------------------------------------------------------
## List: Message Row For Empty List
--------------------------------------------------------------*/
.dd-list-message {
  cursor: inherit !important;

  &:hover {
    background-color: inherit !important;
  }

  .dd-list-item {
    height: inherit;
    align-items: baseline;
  }

  .dd-image {
    border-radius: 0px;
    overflow: inherit;
  }

  .dd-title {
    font-size: 1.1rem;
    color: rgb(53, 141, 198);
  }

  .list-message-caption-level-1 {
    font-size: 1rem;
    color: rgb(53, 141, 198);
  }

  .list-message-caption-level-2 {
    font-size: 0.9rem;
  }

  .chat-list-item p {
    margin: 0px;
  }
}

/*--------------------------------------------------------------
## Input text field
--------------------------------------------------------------*/

.card-footer {
  border-radius: 0;
  border-top: 0 !important;
  position: absolute;
  bottom: 0;
  width: 100%;

  &:last-child {
    border-radius: 0;
  }
}

.textarea-end,
.textarea-start {
  border-radius: 0 150px 150px 0;
  -moz-border-radius: 0 150px 150px 0;
  -webkit-border-radius: 0 150px 150px 0;
  padding: 0 30px 0 0;
  background: #fff;
  border-left: 1px solid white;
}

.textarea-start {
  border-radius: 150px 0px 0px 150px;
  -moz-border-radius: 150px 0px 0px 150px;
  -webkit-border-radius: 150px 0px 0px 150px;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus,
.form-control:focus {
  box-shadow: none; // just for now - in a future update we shoulde highlight the whole textbox including the rounded area
}

.card-footer-disable {
  background: #e8e8e8 !important;

  .form-control:disabled,
  .textarea-end,
  .textarea-start,
  .attach-btn {
    background: #f5f5f5 !important;
  }

  .type-msg {
    color: rgba(51, 51, 51, 0.5);
  }
}

.attach-btn {
  border-radius: 150px 0 0 150px !important;
  background-color: #ffffff;
  border: 0;
  color: #e8e8e8;
  cursor: pointer;
}

.btn.send-btn {
  border-radius: 50% !important;
  background-color: #ffffff;
  border: 0;
  color: #dd3c5d;
  cursor: pointer;
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 22px;
  padding: 0px;

  &:disabled {
    opacity: 0.3;
    color: #e8e8e8;
    cursor: not-allowed;
  }
}

.type-msg {
  background-image: none !important;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #ffffff;
  border: 0;
  color: rgba(51, 51, 51, 0.8);
  height: 4rem;
  overflow-y: auto;
  resize: none;
}

.type_msg:focus {
  box-shadow: none;
  outline: 0px;
}

.send-btn {
  height: 38px;
  width: 38px;
  line-height: 38px;
  padding: 38px;
}

.send-btn i {
  margin: auto;
}

textarea.type-msg {
  min-height: 38px;
  max-height: 90px;
  padding-left: 8px;
}

/*
 * Modals
 * ---------------------------------
 */

/*
 * Loading indicator
 * ---------------------------------
 */
body,
html {
  height: 100%;
  //overflow: auto;
  min-height: $min-body-height;
}

.app-loading {
  .logo {
    width: 64px;
    height: 64px;
    background: url(./assets/dd-logo.png) center center no-repeat;
    background-size: cover;
  }
}

/*
 * Unread message Counters on left tabs
 */
.counter-actual-int-chat {
  position: absolute;
  top: -5px;
  right: 170px;
  z-index: 100;
}

.counter-archive-int-chat {
  position: absolute;
  top: -5px;
  right: 52px;
  z-index: 100;
}

.counter-private-chat {
  position: absolute;
  top: -5px;
  left: 280px;
  z-index: 100;
}

// make badge-pill a circle for single digits
.badge-pill {
  padding-left: 0.46em;
  padding-right: 0.46em;
}

.dynamicChatMsgContainerHeight-reduce {
  height: calc(100vh - 295px) !important;
  min-height: calc(#{$min-body-height} - 295px) !important;
}

.dynamicPublicCommentMsgContainerHeight-reduce {
  height: calc(100vh - 333px) !important;
  min-height: calc(#{$min-body-height} - 333px) !important;
}

.burgerModal {
  .modal-title {
    height: 28px;
    width: 482px;
    color: #ffffff;
    font-family: Lato;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 4px;
    line-height: 22px;
  }

  .modal-dialog {
    max-width: 60%;
    min-width: 500px;
  }
  .modal-header {
    height: 180px;
    padding: 120px 120px 80px 120px;
  }
  .modal-body {
    min-height: 500px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    line-height: 22px;
    padding: 120px !important;
  }
  .close-dialog-btn {
    // position: absolute;
    // right: 20px;
    // top: 20px;
    padding: 0;
    color: white;
    font-size: 3em;
    font-weight: 50;
    margin-right: -100px;
    margin-top: -120px;
  }
  .caption {
    height: 49px;
    width: 786px;
    color: #ffffff;
    font-family: Lato;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 25px;
  }
}

.addWorkerDialog {
  overflow: hidden !important;
  .modal-dialog {
    height: 100%;
    max-width: 80%;
    min-width: 500px;
    margin: 1.75rem auto;
    visibility: hidden;
  }
  .modal-content {
    max-height: 90%;
  }
}

.datenAnfordernModal,
.editProfileModal,
.deleteAccountModal {
  .modal-header {
    background-color: rgb(59, 145, 199);
    padding: 40px;
    display: block;
  }
  .modal-title {
    color: #ffffff;
    font-family: Lato;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 4px;
  }
  .modal-dialog {
    max-width: 50%;
    min-width: 500px;
  }
  .modal-body {
    min-height: 400px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    padding: 40px !important;
  }
  .close-dialog-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    color: white;
    font-size: 2em;
    font-weight: 10;
  }
  .badge-img {
    top: -50px;
    border-radius: 50%;
    right: 30px;
    border: 5px solid white;
    position: absolute;
    height: 150px;
  }
  .btn-link {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
  .red-btn {
    width: 200.52px;
    border-radius: 26.4px;
    background-color: #dd3c5d;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  p {
    width: 70%;
  }
}

.editProfileModal {
  .modal-header {
    padding: 40px 0px 5px 40px;
  }
  .img-group {
    top: -50px;
    right: 30px;
    position: absolute;
    p {
      text-align: center;
      margin: 0;
    }
    label {
      color: #1f82c0;
      cursor: pointer;
      font-weight: 400;
    }
    label:hover {
      text-decoration: underline;
    }
    .badge-img {
      top: 0px;
      right: 0px;
      position: relative;
      width: 150px;
      object-fit: cover;
    }
  }
  .btn-link {
    color: #dd3c5d;
  }
  .caption {
    color: #ffffff;
    font-family: Lato;
    font-style: italic;
  }
  .textarea-start {
    border: 2px solid #ced4da;
    border-right: none;
  }
  .textarea-end {
    border: 2px solid #ced4da;
    border-left: none;
  }
  input {
    border: 2px solid #ced4da;
    border-left: none;
    border-right: none;
  }
  .form-control:focus {
    box-shadow: none;
    outline: 0px;
    border-color: #ced4da;
  }
  .invalid-input,
  .invalid-input:focus {
    border-color: #dd3c5d !important;
  }
  .input-group {
    width: 70%;
    margin-bottom: 10px;
  }
  p,
  label {
    width: 100%;
  }
  label {
    font-weight: 600;
  }
}
.deleteAccountModal {
  .modal-header {
    background-color: #fff;
    background-image: linear-gradient(
      to right,
      rgba(221, 60, 93, 0.7),
      rgba(221, 60, 93, 1)
    );
  }
  h3 {
    margin-bottom: 20px;
  }
  .btn-primary {
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    margin-top: 15px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 0;
    width: 100%;
  }
}

.datenAnfordernModal,
.editProfileModal,
.deleteAccountModal,
.deleteSuccessModal,
.errorModal,
.dataRequestSuccessModal {
  .modal-header {
    margin-right: -1px;
    margin-left: -1px;
    background-color: rgb(59, 145, 199);
    padding: 40px;
    display: block;
  }
  .modal-title {
    color: #ffffff;
    font-family: Lato;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 4px;
  }
  .modal-dialog {
    max-width: 50%;
    min-width: 500px;
  }
  .modal-body {
    min-height: 400px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    padding: 40px !important;
  }
  .close-dialog-btn {
    text-shadow: none;
    color: #fff;
    font-size: 3em;
    font-weight: 100;
    opacity: 0.9;
    z-index: 1000;
    position: absolute;
    padding: 0;
    margin: 0;
    right: 40px;
    top: 0px;
  }
  .badge-img {
    top: -50px;
    border-radius: 50%;
    right: 30px;
    border: 5px solid white;
    position: absolute;
    height: 150px;
  }
  .btn-link {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
  .red-btn {
    width: 160px;
    border-radius: 30px;
    background-color: #dd3c5d;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  p {
    width: 70%;
  }
}

.deleteAccountModal .red-btn {
  background-color: white;
  box-shadow: none;
  border-color: #dd3c5d;
  color: #dd3c5d;
  font-weight: 600;
}

.editProfileModal {
  .modal-header {
    margin-right: -1px;
    margin-left: -1px;
    padding: 40px 0px 5px 40px;
  }
  .img-group {
    top: -50px;
    right: 30px;
    position: absolute;
    p {
      text-align: center;
      margin: 0;
    }
    label {
      color: #1f82c0;
      cursor: pointer;
      font-weight: 400;
    }
    label:hover {
      text-decoration: underline;
    }
    .badge-img {
      top: 0px;
      right: 0px;
      position: relative;
      width: 150px;
      object-fit: cover;
    }
  }
  .btn-link {
    color: #dd3c5d;
  }
  .caption {
    color: #ffffff;
    font-family: Lato;
    font-style: italic;
  }
  .textarea-start {
    border: 2px solid #ced4da;
    border-right: none;
  }
  .textarea-end {
    border: 2px solid #ced4da;
    border-left: none;
  }
  input {
    border: 2px solid #ced4da;
    border-left: none;
    border-right: none;
  }
  .form-control:focus {
    box-shadow: none;
    outline: 0px;
    border-color: #ced4da;
  }
  .input-group {
    width: 70%;
    margin-bottom: 10px;
  }
  p,
  label {
    width: 100%;
  }
  label {
    font-weight: 600;
  }
}
.deleteAccountModal {
  .modal-header {
    background-color: #fff;
    background-image: linear-gradient(
      to right,
      rgba(221, 60, 93, 0.7),
      rgba(221, 60, 93, 1)
    );
  }
  h3 {
    margin-bottom: 20px;
  }
  .btn-primary {
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    margin-top: 15px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 0;
    width: 100%;
  }
}
.deleteSuccessModal,
.dataRequestSuccessModal,
.errorModal {
  .red-btn {
    position: relative;
    bottom: 0;
  }
  .modal-dialog {
    max-width: 40%;
  }
  .badge-img {
    top: 0;
  }
  .caption {
    color: white;
  }
  p {
    width: 100%;
  }
}

.errorModal {
  .modal-header {
    background-color: #eee !important;
  }
  .badge-img {
    border: none;
  }
  h2,
  p {
    color: black !important;
  }
}
